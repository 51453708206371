import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import Content, { HTMLContent } from "../components/Content";
import BlogBanner from "../components/BlogBanner/BlogBanner";
import Hero from "../components/Hero/Hero";
import styled from "styled-components";
import { withPrefix } from "gatsby";

const PostWrapper = styled.div`
  max-width: 944px;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            {/* <p>{description}</p> */}
            <PostWrapper>
              <PostContent content={content} />
            </PostWrapper>
            {/* {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const url =
    typeof window !== "undefined"
      ? window.location.protocol + "//" + window.location.hostname
      : "";

  return (
    <Layout>
      <Hero smaller subtitle={post.frontmatter.date}>
        {post.frontmatter.title}
      </Hero>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              name="og:description"
              content={`${post.frontmatter.description}`}
            />
            <title>Cosmic | {post.frontmatter.title}</title>
            <meta
              property="og:title"
              content={`Cosmic | ${post.frontmatter.title}`}
            />
            <meta
              property="og:image"
              content={url + post.frontmatter.featuredimage.publicURL}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
      <BlogBanner />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          id
          publicURL
        }
      }
    }
  }
`;
